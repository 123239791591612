import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { color } from 'const/colors'
import InfoCard from './InfoCard'
import CtaCard from './CtaCard'
import media from 'styles/media'

const Container = styled.div`
  margin-bottom: 130px;
`

const UpperWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: right;

  ${media.tablet`
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
  `}

  ${media.phone`
   justify-content: space-between;
   flex-flow: column;
   align-items: flex-start;
  `}
`

const TitleWrapper = styled.div`
  position: absolute;
  flex: 1;
  height: 88vh;
  left: 125px;
  ${media.tablet`
    position: relative;
    height: auto;
      left: 0px;

  `}

  ${media.phone`
    position: relative;
    height: auto;
    left: 0px;
  `}
`

const Title = styled.div`
  max-width: 400px;
  word-wrap: break-word;
  color: ${color.black.o100};
  font-size: 72px;
  font-weight: 700;
  line-height: 80px;
  margin-right: 50px;
  @supports (position: sticky) {
    position: sticky;
    top: 0; // Threshold is required
  }

  ${media.tablet`
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-right: 0;
    margin-bottom: 36px;
    padding: 0 24px;
    position: relative;
  `}

  ${media.phone`
    position: relative;
  `}
`

const InfoCardsContainer = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 620px;
  height: fit-content;
`

const BottomContainer = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 1416px;
  margin: 0 auto;

  ${({ itemKey }) => {
    if (itemKey === 0) {
      return css`
        margin-top: -100px;
        margin-bottom: -210px;
      `
    }
    if (itemKey === 1) {
      return css`
        margin-top: 80px;
        margin-bottom: -400px;
      `
    }
    if (itemKey === 2) {
      return css`
        align-items: center;
        align-content: center;
      `
    }
  }}

  ${media.tablet`
    margin: 10px auto -125px;
    max-width: 480px;
  `}

  ${media.phone`
    margin: ${({ itemKey }) =>
      itemKey !== 2 ? '10px auto -125px' : '10px auto -178px'};
  `}
`

const LargeImgWrapper = styled.div`
  display: flex;

  ${({ itemKey }) => {
    if (itemKey === 0) {
      return css`
        align-self: center;
        order: 2;
        justify-content: center;
        width: 71.25%;
      `
    }
    if (itemKey === 1) {
      return css`
        align-self: flex-start;
        order: 1;
        width: 60.45%;
      `
    }
    if (itemKey === 2) {
      return css`
        width: 136.955%;
      `
    }
  }}

  ${media.tablet`
    order: 1;
    width: 100%;
    padding: 0 24px;
    justify-content: flex-start;
    z-index: 3;
  `}
`

const SmallImgWrapper = styled.div`
  display: flex;

  ${({ itemKey }) => {
    if (itemKey === 0) {
      return css`
        align-self: flex-end;
        order: 3;
        transform: translateY(-210px);
        width: 36.16%;
      `
    }
    if (itemKey === 1) {
      return css`
        align-self: flex-end;
        order: 3;
        transform: translateY(-400px);
        width: 37.85%;
      `
    }
    if (itemKey === 2) {
      return css`
        width: 30.37%;
      `
    }
  }}

  ${media.tablet`
    order: 2;
    width: 100%;
    padding: 0 24px;
    justify-content: flex-end;
    transform: translate(0, -34px);
    top: 34px;
    margin-bottom: 0;
    z-index: 2;
  `}
`

const CtaCardWrapper = styled.div`
  display: flex;

  ${({ itemKey }) => {
    if (itemKey === 0) {
      return css`
        align-self: flex-start;
        order: 1;
        transform: translateY(210px);
      `
    }
    if (itemKey === 1) {
      return css`
        align-self: center;
        order: 2;
        transform: translate(130px, -435px);
      `
    }
    if (itemKey === 2) {
      return css`
        align-self: flex-end;
        transform: ${({ ctaCardH }) => `translate(-144px, ${ctaCardH / 2}px)`};
      `
    }
  }}

  ${media.tablet`
    order: 3;
    width: 100%;
    transform: translate(0, -48px);
    z-index: 1;
  `}
`

const LargeImg = styled.img`
  width: 100%;
  object-fit: scale-down;

  ${media.tablet`
    width: 94.875%;
    height: 100%;
  `}
`

const SmallImg = styled.img`
  width: 173%;
  z-index: 20;
  object-fit: scale-down;

  ${media.tablet`
    width: 94.875%;
    height: 100%;
  `}
`

const CenterAlignedWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;

  ${media.tablet`
    flex-flow: column nowrap;
    width: 100%;
    transform: translate(0, 6px);
    z-index: 1;
  `}
`

class InfoArea extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ctaCardH: null,
    }

    this.ctaCardWrapperRef = React.createRef()
  }

  componentDidMount() {
    if (this.ctaCardWrapperRef.current) {
      this.setState({ ctaCardH: this.ctaCardWrapperRef.current.offsetHeight })
    }
  }

  render() {
    const {
      itemKey,
      title,
      infoCards,
      ctaText,
      ctaLinkName,
      ctaLinkSlug,
      largeImage,
      smallImage,
      id,
    } = this.props

    return (
      <Container id={id}>
        <UpperWrapper>
          <TitleWrapper id={`${id}`}>
            <Title>{title}</Title>
          </TitleWrapper>
          <InfoCardsContainer>
            {infoCards.map((item, i) => (
              <InfoCard
                key={i}
                title={item.elements.title.value}
                description={item.elements.description.value}
              />
            ))}
          </InfoCardsContainer>
        </UpperWrapper>
        <BottomContainer itemKey={itemKey}>
          <CtaCardWrapper
            itemKey={itemKey}
            ref={this.ctaCardWrapperRef}
            ctaCardH={this.state.ctaCardH}
          >
            <CtaCard
              ctaText={ctaText}
              ctaLinkName={ctaLinkName}
              ctaLinkSlug={ctaLinkSlug}
            />
          </CtaCardWrapper>
          {itemKey !== 2 ? (
            <>
              <LargeImgWrapper itemKey={itemKey}>
                <LargeImg src={largeImage} />
              </LargeImgWrapper>
              <SmallImgWrapper itemKey={itemKey}>
                <SmallImg src={smallImage} />
              </SmallImgWrapper>
            </>
          ) : (
            <>
              <CenterAlignedWrapper>
                <SmallImgWrapper itemKey={itemKey}>
                  <SmallImg src={smallImage} />
                </SmallImgWrapper>
                <LargeImgWrapper itemKey={itemKey}>
                  <LargeImg src={largeImage} />
                </LargeImgWrapper>
              </CenterAlignedWrapper>
            </>
          )}
        </BottomContainer>
      </Container>
    )
  }
}

InfoArea.propTypes = {
  itemKey: PropTypes.number,
  title: PropTypes.string,
  infoCards: PropTypes.array,
  ctaText: PropTypes.string,
  ctaLinkName: PropTypes.string,
  ctaLinkSlug: PropTypes.string,
  largeImage: PropTypes.string,
  smallImage: PropTypes.string,
  id: PropTypes.string,
}

export default InfoArea
