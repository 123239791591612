import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { pink, white } from 'const/colors'
import media from 'styles/media'
import Link from 'components/Link'

const Container = styled.div`
  background-color: ${pink};
  padding: 24px;
  max-width: 312px;

  ${media.tablet`
    max-width: 100%;
    padding: 36px 24px 40px;
  `}
`

const BodyText = styled.div`
  color: ${white};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
  `}
`

const StyledLink = styled(Link)`
  color: ${white};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: inline-block;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: ${white};
    margin-top: 4px;
    transition: width 0.2s;
  }

  &:hover:after {
    width: 0;
  }

  ${media.phone`
    font-size: 20px;
    line-height: 28px;
  `}
`

const CtaCard = ({ ctaText, ctaLinkName, ctaLinkSlug }) => {
  return (
    <Container>
      <BodyText>{ctaText}</BodyText>
      <StyledLink to={ctaLinkSlug} color={white}>
        {ctaLinkName}
      </StyledLink>
    </Container>
  )
}

CtaCard.propTypes = {
  ctaText: PropTypes.string,
  ctaLinkName: PropTypes.string,
  ctaLinkSlug: PropTypes.string,
}

export default CtaCard
