import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { black } from 'const/colors'
import media from 'styles/media'

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 40px;
  max-width: 650px;
`

const Title = styled.div`
  color: ${black};
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
    padding: 0 24px;
    margin-bottom: 16px;
  `}
`

const Description = styled.div`
  opacity: 0.5;
  color: ${black};
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  ${media.tablet`
    font-size: 18px;
    padding: 0 24px;
  `}
`

const InfoCard = ({ title, description }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default InfoCard
