/* eslint-disable no-useless-escape */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Layout from 'layouts/Default'
import HeaderSection from 'components/Section/headerSection'
import InfoArea from 'components/Approach/InfoArea'
import ServicesSection from 'components/Approach/Services'
import ContactSection from 'sections/Shared/ContactSection'
import media from 'styles/media'

const PageContainer = styled.div`
  & * {
    box-sizing: border-box;
  }
`

const InfoAreas = styled.section`
  width: 100%;
  padding: 0 248px;
  margin-top: 135px;
  height: fit-content;

  ${media.desktop`
    padding: 0 136px;
  `}

  ${media.tablet`
    padding: 0;
    margin-top: 16px;
  `}

  ${media.phone`
    padding: 0;
    margin-top: 60px;
  `}
`

const BreakerImage = styled.img`
  display: none;
  width: 100%;
  margin-top: 30px;

  ${media.phone`
    display: block;
  `}
`

// const StickyAnchors = styled.div`
//   display: ${({ hideStickyAnchor }) => (hideStickyAnchor ? 'none;' : 'flex;')}
//   flex-direction: column;
//   position: fixed;
//   width: fit-content;
//   background: rgb(12 12 12 / 80%);
//   padding: 20px;
//   gap: 8px;
//   border-top-right-radius: 10%;
//   border-bottom-right-radius: 10%;
//   top: 47%;
//   z-index: 20;

//   @media (max-width: 1280px) {
//     display: none;
//   }
// `

// const StickyAnchorsLinks = styled.a`
//   color: darkgray;
//   &:hover {
//     color: gray;
//   }
// `

// const ShowStickyAnchor = styled.button`
//   display: ${({ hideStickyAnchor }) => (hideStickyAnchor ? 'flex;' : 'none;')}
//   flex-direction: column;
//   position: fixed;
//   width: 3px;
//   height: 120px;
//   border: none;
//   background: rgb(12 12 12 / 80%);
//   padding: 20px;
//   gap: 8px;
//   border-top-right-radius: 10%;
//   border-bottom-right-radius: 10%;
//   top: 47%;
//   cursor: pointer;
//   z-index: 21;
//       justify-content: center;
//     align-items: center;
//     color: darkgray;
//     font-size: larger;

//   &:hover {
//     opacity: 0.8;
//   }

//   @media (max-width: 1280px) {
//     display: none;
//   }
// `

class Solutions extends React.Component {
  state = {
    isContactFormModalOpen: false,
    hideStickyAnchor: true,
  }

  toggleScrollBody = (isContactFormModalOpen) => {
    this.setState({
      isContactFormModalOpen,
    })
  }

  toggleStickyAnchors = () => {
    this.setState({ hideStickyAnchor: false })
  }

  handleSticky = (event) => {
    const stickyAnchors = document.getElementById('sticky-anchors')
    const stickyAnchotsButton = document.getElementById('sticky-anchors-button')
    // Check if the click was outside the stickyAnchors div
    if (
      stickyAnchors &&
      !stickyAnchors.contains(event.target) &&
      !stickyAnchotsButton.contains(event.target) &&
      !this.state.hideStickyAnchor
    ) {
      this.setState({ hideStickyAnchor: true })

      return
    }
  }

  componentDidMount() {
    this.scrollToHashElement()
    window.addEventListener('hashchange', this.scrollToHashElement)
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.scrollToHashElement)
  }

  scrollToHashElement = () => {
    const hash = window.location.hash
    if (hash) {
      const id = hash.substring(1) // Remove the '#' character
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  render() {
    const { ...restProps } = this.props
    const { isContactFormModalOpen } = this.state
    const {
      title,
      tagline,
      info_area,
      mobile_breaker_image,
      services_tagline,
      services_title,
      services_transitioned_title,
      services_left_column,
      services_centre_column,
      services_right_column,
    } = this.props.data.kenticoCloudItemApproach.elements
    return (
      <Layout {...restProps} isContactFormModalOpen={isContactFormModalOpen}>
        <Helmet>
          <link
            rel="canonical"
            href={`${process.env.GATSBY_ROBOTS_SITE_URL}/solutions`}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: 'https://distologystudios.com/solutions',
                name: 'Solutions',
                description:
                  'We combine our IAM credentials with proven product implementation and delivery expertise. Whether it’s an ambitious startup or established enterprise platform, we always focus our approach on three main areas: Identity Consultancy, Design &amp; Engineering and Product Management.',
              }),
            }}
          />
        </Helmet>
        <PageContainer>
          {/* <ShowStickyAnchor
            id="sticky-anchors-button"
            onClick={this.toggleStickyAnchors}
            {...this.state}
          >
            {`${'>'}`}
          </ShowStickyAnchor>
          <StickyAnchors id="sticky-anchors" {...this.state}>
            {info_area.map((item, i) => {
              return (
                <StickyAnchorsLinks
                  key={i}
                  href={`#${item.elements.anchor_id.value}`}
                >
                  {' '}
                  {item.elements.title.value}
                </StickyAnchorsLinks>
              )
            })}
          </StickyAnchors> */}
          <HeaderSection
            header
            headline={title.value}
            tagline={tagline.value}
            taglineOpacity={1}
            maxWidth={'910px'}
            width={'75%'}
          />
          <BreakerImage src={mobile_breaker_image.value[0].url} />

          <InfoAreas>
            {info_area.map((item, i) => {
              return (
                <InfoArea
                  key={i}
                  id={item.elements.anchor_id.value}
                  itemKey={i}
                  title={item.elements.title.value}
                  infoCards={item.elements.info_cards}
                  ctaText={item.elements.cta_text.value}
                  ctaLinkName={item.elements.cta_link_name.value}
                  ctaLinkSlug={item.elements.cta_link_slug.value}
                  largeImage={
                    item.elements.large_image.value[0] &&
                    item.elements.large_image.value[0].url
                  }
                  smallImage={
                    item.elements.small_image.value[0] &&
                    item.elements.small_image.value[0].url
                  }
                />
              )
            })}
          </InfoAreas>
          <ServicesSection
            tagline={services_tagline.value}
            title={services_title.value}
            transitionedTitle={services_transitioned_title.value}
            leftColumn={services_left_column.resolvedHtml}
            centreColumn={services_centre_column.resolvedHtml}
            rightColumn={services_right_column.resolvedHtml}
          />
          <ContactSection toggleScrollBody={this.toggleScrollBody} />
        </PageContainer>
      </Layout>
    )
  }
}

Solutions.propTypes = {
  data: PropTypes.shape({
    kenticoCloudItemApproach: PropTypes.shape({
      elements: PropTypes.shape({
        title: PropTypes.shape({ value: PropTypes.string }),
        tagline: PropTypes.shape({ value: PropTypes.string }),
        info_area: PropTypes.array,
        mobile_breaker_image: PropTypes.shape({ value: PropTypes.array }),
        services_tagline: PropTypes.shape({ value: PropTypes.string }),
        services_title: PropTypes.shape({ value: PropTypes.string }),
        service_items: PropTypes.shape({
          elements: PropTypes.shape({
            title: PropTypes.shape({ value: PropTypes.string }),
            body_text: PropTypes.shape({ value: PropTypes.string }),
            slug: PropTypes.shape({ value: PropTypes.string }),
          }),
        }),
        services_transitioned_title: PropTypes.shape({
          value: PropTypes.string,
        }),
        services_left_column: PropTypes.shape({
          resolvedHtml: PropTypes.string,
        }),
        services_centre_column: PropTypes.shape({
          resolvedHtml: PropTypes.string,
        }),
        services_right_column: PropTypes.shape({
          resolvedHtml: PropTypes.string,
        }),
      }),
    }),
  }),
}

export default Solutions

export const query = graphql`
  {
    kenticoCloudItemApproach {
      elements {
        title {
          value
        }
        tagline {
          value
        }
        mobile_breaker_image {
          value {
            url
          }
        }
        info_area {
          elements {
            title {
              value
            }
            info_cards {
              elements {
                title {
                  value
                }
                description {
                  value
                }
              }
            }
            cta_text {
              value
            }
            anchor_id {
              value
            }
            cta_link_name {
              value
            }
            cta_link_slug {
              value
            }
            large_image {
              value {
                url
              }
            }
            small_image {
              value {
                url
              }
            }
          }
        }
        services_tagline {
          value
        }
        services_title {
          value
        }
        services_transitioned_title {
          value
        }
        services_left_column {
          resolvedHtml
        }
        services_centre_column {
          resolvedHtml
        }
        services_right_column {
          resolvedHtml
        }
      }
    }
  }
`
